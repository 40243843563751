import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Jordie Bodlay - Full-stack web developer" />

    <div className="header-bg">
      <p className="wave-hi">Hi!  <span role="img" aria-label="Waving hand">👋</span></p>
      <h2>I'm Jordie Bodlay.</h2>
      <h1>I'm a full-stack web developer in Sydney, Australia</h1>
    </div>

    <div
      style={{
        maxWidth: 960,
        padding: `20px 1.0875rem 1.45rem`,
      }}
    >
      <p>I work in all aspects of a modern web stack including devops.</p>
      <p>I work full-time, but am sometimes available for freelance work.</p>
      <p>
        My areas of expertise include but are <u>not</u> limited to:
      </p>
      <ul>
        <li>ReactJS</li>
        <li>NodeJS</li>
        <li>PHP</li>
        <li>Nginx</li>
        <li>Docker</li>
        <li>Kubernetes</li>
        <li>AWS</li>
      </ul>
      <br />
      <p>If you wish to get in touch, please use the form:</p>
      <div>
        <form
          action="https://getform.io/f/731822ba-732d-4ab1-af9c-b00da26e5bc3"
          method="POST"
        >
          Name: <input type="text" name="name" required />
          <br />
          Email: <input type="email" name="email" required />
          <br />
          <textarea
            name="message"
            placeholder="Your Message"
            required
          ></textarea>
          <br />
          <button type="submit">Send</button>
        </form>
        <br />
        <br />
      </div>
    </div>
  </Layout>
)

export default IndexPage
